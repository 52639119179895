import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

interface BetaPopupDesktopProps {
  onClose: () => void;
}

const BetaPopupDesktop: React.FC<BetaPopupDesktopProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
      setIsVisible(false);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleExited = () => {
    // Handle any necessary cleanup or actions after the transition has completed
  };

  return (
    <Transition in={isVisible} timeout={300} mountOnEnter unmountOnExit onExited={handleExited}>
      {(state) => (
        <div
          id="BetaPopup"
          className={`bg-[#f0c328] w-full sm:w-auto border-jacarta-100 font-medium text-black rounded-t-lg py-3 px-3 transition-all duration-300 fixed bottom-0 right-0 sm:max-w-[400px] sm:max-h-[500px] z-50 sm:rounded-tl-lg  ${
            state === 'exited' ? 'opacity-0 translate-y-full' : 'opacity-100 translate-y-0'
          } flex justify-between items-center`}
        >
           <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="#000"
              className="bi bi-bell-fill -mt-[50px] sm:-mt-12 mr-3 md:mr-5 fill-jacarta-700 h-4 w-4 transition-colors"
              viewBox="0 0 16 16"
            >
              <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
            </svg>
            <span className="ml-2 flex-1">During our beta phase, please understand that minor bugs might be encountered.</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="black"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className={`h-6 w-6 cursor-pointer ml-3 sm:-mt-14 -mt-12 md:ml-5 sm:-mr-1 ${
                state === 'entered' ? 'transform hover:rotate-180 transition-transform duration-300' : ''
              }`}
              onClick={handleClose}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
        </div>
      )}
    </Transition>
  );
};

export default BetaPopupDesktop;