import React, { useState } from "react";
import {
  Hero, Bids, Top_collection, Tranding_category, NewseLatter, Feature_collections
} from "../../components/component";
import Collection_items_home from "../../components/collectrions/Collection_items_home";
import Meta from "../../components/Meta";
import Link from "next/link";
import BetaPopup from "../../components/pop-ups/betaPopup"
import Template from "../../components/pop-ups/ZZZTemplatePopup";

export default function Homepage() {
  const [popupVisible, setPopupVisible] = useState(true);

  const handlePopupClose = () => {
    setPopupVisible(false);
  };

  return (
    <main>
      <Meta />
      <div className="fixed bottom-0 right-0 flex flex-col-reverse">
        {/* More popups here */}
      </div>
      <div className={`-mb-[20px] sm:-mb-[30px] ${popupVisible ? 'sm:mt-[105px] mt-[25px]' : 'mt-5'}`}>
        <div>
          <BetaPopup  onClose={() => console.log("popup closed.")}/>
        </div>
      </div>
      <div className={`${popupVisible ? '-mt-[70px]' : 'mt-10'}`}>
        <Hero />
      </div>
        <Bids />

        <div className="max-w-[1620px] mx-auto">
          <Collection_items_home limit={10}/>
        </div>
        <div className="mx-auto text-center h-[80px]">
          <Link href="/collections">
            <button
            className="hover:bg-accent-dark hover:scale-105 bg-accent rounded-xl px-10 py-4 mb-3 w-[220px] font-bold text-sm text-white transition-all">
              View All Collections
            </button>
          </Link>
        </div>
      <NewseLatter />
    </main>
  );
};
